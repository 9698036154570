.footer {
    background-color: var(--footer-background-color);
    box-sizing: border-box;
    color: var(--footer-text-color);
    font-size: var(--footer-font-size);
    font-weight: bold;
    min-height: 30px;
    padding-block: 10px;

    a {
        color: var(--footer-text-color);
        text-decoration: none;
    }
}

.logo {
    width: 20px;
    padding: 0;
    margin: 0;
    height: auto;
    background-color: white;
}

.innerFooter {
    flex-wrap: wrap;
}

.language {
    display: -webkit-flex;
    display: flex;
}